/*
Theme Name:
Author:
Author URI:
Text Domain:
*/

// libraries and dependencies
@import 'vendor/_normalize';


// UTILITIES
@import 'utility/_variables';
@import 'utility/_fonts';
@import 'utility/_mixins';


// REUSABLE COMPONENTS
@import 'components/_button';


// GLOBAL
@import 'global/_global';
@import 'global/_header';


// PAGE LAYOUTS
@import 'layout/_homepage';

