/*************************************************************
   * GENERAL
*************************************************************/

// fonts and headings are set in "/utility/_fonts.scss"

* {
	box-sizing: border-box;
}

html, body {
	@include default-font;
}

@mixin container(){
	position:relative;
	max-width:$container;
	padding:0 $site_container_padding;
	margin:0 auto;
	@media (max-width:$x-large-screens) {
		max-width:$container_laptop;
		padding:0 $site_container_padding_laptop;
	}
	@media (max-width:$mobile) {
		max-width:$container_mobile;
		padding:0 $site_container_padding_mobile;
	}
}

.container{
	@include container();
}

.skip-link {
    position: absolute;
    top: -50px;
}

#main{
	position:relative;
	padding-top:$header_height;
	@media screen and (max-width: #{$mobile}) {
		padding-top:$header_height_mobile;
	}
}


/********************************
	* UTILITY
********************************/
.clearfix {
	@include clearfix();
}

.accessibility,
.sr_only{
	@include sr_only();
}

.scroll--lock{
	overflow:hidden;
}

.disabled{
	pointer-events:none;
	opacity:0.5;
	cursor:not-allowed;
}



/********************************
	* LAYOUT
********************************/
// disable tel links on desktop
a[href^="tel:"] {
	@media (min-width: $tablet) {
		pointer-events: none;
	}
}

.background-cover {
	@include background-size(cover);
}

.background-contain {
	@include background-size(contain);
}

.bullet-list {
	list-style: outside disc;
	padding-left: 30px;
}

.number-list {
	list-style: outside decimal;
	padding-left: 30px;
	margin: 0;
}
