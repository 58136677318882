/********************************
	GLOBALS
********************************/
.section {
    position: relative;
}

.section__intro {
    text-align: center;
}

#main {
    padding-top: calc(#{$header_height} + 50px);

    @media (max-width: $mobile) {
        padding-top: calc(#{$header_height_mobile} + 50px);
    }
}


/********************************
	HERO SECTION
********************************/
.section--hero {
    overflow: hidden;

    .section__intro {
        img {
            margin: 0 auto;
        }
    }

    .hero__feature {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // needs bottom padding while the hero cta section is removed so that the fruit pics have enough room :)
        padding-bottom: 80px;

        @media (max-width: $tablet) {
            display: block;
        }

        // HERO ITEMS - GLOBAL
        .hero__item {
            position: relative;
            flex: 1 1 calc(50% - 30px);
            display: flex;
            align-items: center;
            background-size: cover;
            background-position: center;
            border-radius: $border-radius;
            padding: 40px 3%;
            min-height: 530px;
            box-shadow: $box-shadow;

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                background-color: rgba($black, 0.5);
                border-radius: $border-radius;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
            }

            &+.hero__item {
                margin-left: 60px;

                @media (max-width: $tablet) {
                    margin: 60px 0 0;
                }
            }
        }

        .hero__item--inner {
            position: relative;
            text-align: center;
            width: 100%;
            z-index: 2;

            h2,
            p {
                color: $white;
                margin: 0;
            }
        }

        .hero__accent {
            width: 85%;
            position: absolute;
            left: 50%;
            bottom: -21%;
            transform: translate(-50%, 0);
            z-index: 5;

            @media (max-width: $tablet) {
                width: 50%;
                bottom: -12%;
            }
        }

        // LEFT ITEM
        .hero__item--left {
            .hero__accent {
                transform: translate(-110%, 0);

                @media (max-width: $tablet) {
                    transform: translate(-130%, 0);
                }
            }
        }

        // RIGHT ITEM
        .hero__item--right {
            .hero__accent {
                transform: translate(10%, 0);

                @media (max-width: $tablet) {
                    transform: translate(30%, 0);
                }
            }
        }
    }

    .hero__cta {
        max-width: 500px;
        margin: 40px auto 20px;
        text-align: center;

        @media (max-width: $tablet) {
            margin-top: 60px;
        }

        @media (max-width: $tablet) {
            margin-top: 80px;
        }

        .icon {
            margin: 10px auto 0;
            max-width: 30px;
        }
    }

}

// close: .section--hero


/********************************
	MAP SECTION
********************************/
.section--map {
    margin-top: 80px;

    .map__feature {
        position: relative;
    }

    .map__callout {
        position: absolute;
        bottom: 5%;
        left: 0;
        max-width: 390px;
        background-color: $orange;
        text-align: center;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: 30px 40px;
        z-index: 99999;

        @media (max-width: $mobile) {
            position: static;
            margin: 15px auto 30px;
        }

        &>div {
            font-weight: $bold;
            margin: 0 0 12px;
            font-size: 24px;
        }
    }

    .map__accent {
        position: absolute;
        transition: all 0.3s;
        z-index: -1;

        @media (max-width: $x-large-screens) {
            max-width: 130px;
        }

        @media (max-width: $tablet) {
            max-width: 90px;
        }

        @media (max-width: $mobile) {
            display: none;
        }
    }

    .map__accent--left {
        left: 0;
        bottom: -7%;

        @media (max-width: $large-screens) {
            bottom: 3%;
        }

        @media (max-width: $tablet) {
            bottom: 15%;
        }
    }

    .map__accent--right {
        right: 0;
        top: 3%;

        @media (max-width: $large-screens) {
            top: 10%;
        }

        @media (max-width: $tablet) {
            top: 20%;
        }
    }

}

// close: .section--map


/********************************
	CTA
********************************/
.section--cta {
    margin-top: 30px;

    .cta__wrapper {
        display: flex;
        justify-content: space-between;
        max-width: 950px;
        margin: 0 auto;

        @media (max-width: $tablet) {
            display: block;
        }

        .cta__item {
            max-width: 400px;
            text-align: center;

            @media (max-width: $tablet) {
                margin: 0 auto;
            }
        }

        .cta__item--left {
            .btn {
                background-color: $green;
            }

            .cta__item--inner {
                .link {
                    font-family: $font_link;
                    font-size: 20px;
                    color: #009CDE;
                    text-decoration: none;

                    span {
                        color: #43B02A;
                    }
                }
            }
        }

        .cta__item--right {
            @media (max-width: $tablet) {
                margin-top: 50px;
            }

            .btn {
                background-color: $blue;
            }

            .cta__item--inner {
                a {
                    text-decoration: none;
                    font-size: 20px;
                }
            }
        }
    }

}

// close: .section--cta


/********************************
	FOOTER
********************************/
footer.footer {
    font-size: calc(#{$em-base}px * .75);
    padding: 60px 0 40px;
    text-align: center;
}