/*************************************************************
   ** @FONT FACES
*************************************************************/

// add @font-face declarations for locally hosted fonts here
// @font-face {
//     font-family: '';
//     src: url('../../fonts/<filename>');
//     src: url('../../fonts/<filename>') format('eot'),
//          url('../../fonts/<filename>') format('woff'),
//          url('../../fonts/<filename>') format('woff2'),
//          url('../../fonts/<filename>') format('truetype'),
//          url('../../fonts/<filename>') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }


/**
 * Google/Adobe Font Example
 * font-family: 'font-name', sans-serif; // Define what the "font-family" parameter should be
 */
// @import url();


/*************************************************************
   ** FONT THEME STYLES
*************************************************************/

/***** DEFAULT FONTS *****/
@mixin font-appearance(){
	text-rendering: optimizeLegibility;
    font-feature-settings: 'kern';
    font-kerning: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin default-font($color: $black) {
	@include font-appearance();
    font-family: $font_body;
    font-size: $font-threequarter-smaller;
    font-weight: $regular;
    line-height: 1.2;
    color: $color;
    font-style:normal;
}

@mixin default-anchor($color: $black, $hoverColor: $green) {
	@include font-appearance();
    font-family: $font_body;
    font-size: inherit;
    font-weight: $bold;
    font-style:normal;
    text-decoration: underline;
    color: $color;
    transition: all 0.35s;
    &:hover,
    &:focus{
        color:$hoverColor;
    }
}

@mixin default-heading($color: $black) {
	@include font-appearance();
    font-family:$font_body;
    font-weight: $bold;
    line-height: 1.2;
    color: $color;
    font-style:normal;
}




/***** HEADING MIXINS *****/
@mixin h1($color: $black) {
    @include default-heading($color);
    font-size: 45px;
    margin: 0.15em 0 0.35em;

    @media screen and (max-width:$mobile){
        font-size:40px;
    }
}

@mixin h2($color: $black) {
    @include default-heading($color);
    font-size: 36px;
    margin: 0.15em 0 0.35em;

    @media screen and (max-width:$mobile){
        font-size:32px;
    }
}

@mixin h3($color: $black) {
    @include default-heading($color);
    font-size: 32px;
    margin: 0.1em 0 0.3em;

    @media screen and (max-width:$mobile){
        font-size:28px;
    }
}

@mixin h4($color: $black) {
    @include default-heading($color);
    font-size: 28px;
    margin: 0.1em 0 0.3em;

    @media screen and (max-width:$mobile){
        font-size:24px;
    }
}

@mixin h5($color: $black) {
    @include default-heading($color);
    font-size: 21px;
    margin: 0.05em 0 0.25em;

    @media screen and (max-width:$mobile){
        font-size:18px;
    }
}

@mixin h6($color: $black) {
    @include default-heading($color);
    font-size: 18px;
    margin: 0.05em 0 0.25em;

    @media screen and (max-width:$mobile){
        font-size:16px;
    }
}



/***** HEADING CLASSES *****/
h1,
.h1 {
    @include h1($black);
}

h2,
.h2 {
    @include h2($black);
}

h3,
.h3 {
    @include h3($black);
}

h4,
.h4 {
    @include h4($black);
}

h5,
.h5 {
    @include h5($black);
}

h6,
.h6 {
    @include h6($black);
}




/***************************************
    * THEME BASED CLASSES & MIXINS
***************************************/

// SUBHEADS
.subtitle{
    @include default-heading($black);
    font-family: $font_body;
    font-weight: $bold;
    font-size:24px;
    text-transform:uppercase;
    @media screen and (max-width:$mobile){
        font-size:18px;
    }
}


.section__caption{
    @include default-font($black);
    max-width:700px;
    margin:20px auto 30px;
    text-align:center;
}




/***************************************
    * STANDARD ELEMENTS & CLASSES
***************************************/

/***** TEXT/INLINE ELEMENTS *****/
a,
.font--a{
    @include default-anchor();
}

p,
.font--p{
    @include default-font($black);
    margin:0.1em 0 0.3em;
}

b,
strong {
    font-weight: $bold;
}

ul,
ol{
    padding-left: 20px;
}
ul{
    list-style:disc outside;
}
ol{
    list-style:decimal outside;
}
ul li,
ol li {
    line-height: 1.65;
}

blockquote{
	@include default-font($black);
    font-style:italic;
    max-width:890px;
    margin: 1.5em auto;
    padding-left:20px;
    position:relative;
    &:before{
        content:'';
        width:3px;
        height:100%;
        background-color: $black;
        position:absolute;
        left:0;
        top:0;
        bottom:0;
    }

    & > p{
        @include default-font($black);
        font-style:italic;
    }
}



/***** MEDIA ELEMENTS *****/
iframe{
    max-width: 100%;
}

img {
    max-width: 100%;
    display: block;
}


/***** MISC ELEMENTS *****/
cite {
    display: block;
    color: $black;
    font-size: 65%;
    text-align: center;
    font-style: normal;
    margin-top: 1em;
}

address {
    font-style: normal;
}

sub,
sup {
    font-size: 60%;
    line-height: 0;
    position: relative;
}

sub {
    bottom: -0.2em;
    vertical-align: sub;
}

sup {
    top: -0.75em;
    vertical-align: super;
}

pre{
    max-width:100%;
    white-space:pre-wrap;
}
