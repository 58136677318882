//********************************
//	CUSTOM
//********************************
$box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
$border-radius: 39px;

$header_height: 80px;
$header_height_mobile: 130px;


//********************************
//	BREAKPOINTS
//********************************
// '-min' vars used for 'min' vs max @media breakpoints
$small: 350px;
$small-min: 351px;
$mobile-small: 500px;
$mobile-small-min: 501px;
$mobile: 768px;
$mobile-min: 769px;
$tablet: 1024px;
$tablet-min: 1025px;
$medium-screens: 1280px;
$medium-screens-min: 1281px;
$large-screens: 1400px;
$large-screens-min: 1401px;
$x-large-screens: 1600px;
$x-large-screens-min: 1601px;


//********************************
//	COLORS
//********************************
$white: #FFFFFF;
$black: #000000;

// Theme
$green: #40B026;
$blue: #65AFC3;
$orange: #FFC859;



//********************************
//	FONTS
//********************************
$font_body: 'proxima-nova', 'Helvetica', 'Arial', sans-serif;
$font_link: 'Work Sans', 'Helvetica', 'Arial', sans-serif;
$font_awesome: 'Font Awesome 5 Pro', sans-serif;
$font_awesome_brands: 'Font Awesome 5 Brands', sans-serif;

$em-base: 20; /* Henseforth known as 1em  |  used for em() mixin */
$base-font-size: #{$em-base}px;
$font-threequarter-smaller: calc(#{$em-base}px * .75);
// Font Awesome
// set in ./src/sass/library/__library-overwrites.scss

//********************************
//	WEIGHTS
//********************************
$thin: 100;
$light: 300;
$book: 350;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$heavy: 900;



//********************************
//    WIDTHS, HEIGHTS, SPACING
//********************************

/*** CONTAINER VARS ***/
// Creates a content container with built-in padding so your content is always off the edge of the window in a centrally controlled location
$site_container: 1120px; // the max width of the content, not including padding
// Global content padding from container
$site_container_padding: 30px; // Desktop
$site_container_padding_laptop: 60px; //laptop per client
$site_container_padding_mobile: 5%; // Mobile (rec: percentage)
// Define container using calc - allows for easy changes to individual container elements
$container: calc(#{$site_container} + (#{$site_container_padding} * 2)); // Desktop
$container_laptop: calc(#{$site_container} + (#{$site_container_padding_laptop} * 2)); // Laptop per client
$container_mobile: calc(#{$site_container} + (#{$site_container_padding_mobile} * 2)); // Mobile
