/***********************************************
    THEME MIXINS
***********************************************/
// Theme specific mixins



/***********************************************
    GENERAL MIXINS
***********************************************/

// Clearfix
// makes a parent div expand to wrap all floated elements
// to use, add "@include clearfix" to a container
@mixin clearfix {
	&:after {
		content: '';
		clear: both;
		visibility: hidden;
		display: block;
		font-size: 0;
		height: 0;
	}
}


// Accessibility tool
// hides element from layout, but allows for screen readers to focus and read
@mixin sr_only(){
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	padding: 0 !important;
	border: 0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}


// CSS element fade mixin
@mixin element_fade($fade){
	transition:all $t_med;

	@if $fade == "in" {
		opacity:1;
		visibility:visible;
		pointer-events: all;
	} @else if $fade == "out" {
		opacity:0;
		visibility:hidden;
		pointer-events: none;
	}
}


// Background sizing mixin
@mixin background-size($size: 'cover'){
	background-size: $size;
	background-repeat: no-repeat;
	background-position: center center;
}
