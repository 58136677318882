/********************************
	Button
********************************/
.btn{
	cursor:pointer;
	display:inline-block;
	padding:20px 30px;
	margin:30px 0 20px;
	min-width:300px;
	border:none;
	border-radius: 40px;
	font-family:$font_body;
	font-weight: $bold;
	font-size:24px;
	line-height:1;
	text-align:center;
	background-color: $white;
	color: $black;
	text-transform:uppercase;
	text-decoration:none;
	transition:all 0.3s;
	@media (max-width: $mobile) {
		min-width:0;
	}

	&:hover,
	&:focus,
	&:active {
		color: $white;
		background-color: $black;
	}
}
