/********************************
	HEADER
********************************/
$item_offset: 7px;

header.header{
    height:$header_height;
    position:fixed;
    width:100%;
    background-color:$white;
    box-shadow: $box-shadow;
    padding:20px 60px;
    z-index: 9999;
    display:flex;
    justify-content:space-between;
    align-items:center;
    @media (max-width: $tablet) {
        padding:20px 40px;
    }
    @media (max-width: $mobile) {
        height:$header_height_mobile;
        display:block;
        padding:20px 20px;
    }

    .header__item{
        flex:1 1 auto;
        @media (max-width: $mobile) {
            width:100%;
            text-align:center;
        }
    }

    .header__left{
        img,
        strong{
            display:inline-block;
            vertical-align:middle;
        }
        strong{
            margin:$item_offset 0 0 10px;
            text-transform:uppercase;
            @media (max-width: $medium-screens) {
                margin-left:0;
            }
            @media (max-width: $tablet) {
                display:none;
            }
        }
    }

    .header__right{
        @media (max-width: $mobile) {
            margin-top:25px;
        }
        ul,li,a{
            margin:0;
            padding:0;
            line-height:1;
        }
        .menu{
            display:flex;
            justify-content:flex-end;
            list-style:none;
            padding:0;
            margin:$item_offset 0 0;
            @media (max-width: $mobile) {
                justify-content:space-between;
            }

            li{
                @media (max-width: $mobile) {
                    margin:0 3%;
                }
                & + li{
                    margin-left:10%;
                    @media (max-width: $medium-screens) {
                        margin-left:5%;
                    }
                    @media (max-width: $tablet) {
                        margin-left:3.5%;
                    }
                    @media (max-width: $mobile) {
                        margin:0 3%;
                    }
                }
            }

            a{
                text-decoration:none;
                font-size:20px;
                white-space:nowrap;
                @media (max-width: $tablet) {
                    font-size:18px;
                }
                @media (max-width: $mobile) {
                    font-size:16px;
                }
            }
        }
    }


}
